// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-bmw-bmw-f-750-gs-js": () => import("./../../../src/pages/bmw/bmw-f750-gs.js" /* webpackChunkName: "component---src-pages-bmw-bmw-f-750-gs-js" */),
  "component---src-pages-bmw-bmw-f-850-gs-js": () => import("./../../../src/pages/bmw/bmw-f850-gs.js" /* webpackChunkName: "component---src-pages-bmw-bmw-f-850-gs-js" */),
  "component---src-pages-bmw-bmw-r-1200-gs-js": () => import("./../../../src/pages/bmw/bmw-r1200-gs.js" /* webpackChunkName: "component---src-pages-bmw-bmw-r-1200-gs-js" */),
  "component---src-pages-bmw-bmw-r-1200-rt-js": () => import("./../../../src/pages/bmw/bmw-r1200-rt.js" /* webpackChunkName: "component---src-pages-bmw-bmw-r-1200-rt-js" */),
  "component---src-pages-bmw-bmw-r-1250-gs-js": () => import("./../../../src/pages/bmw/bmw-r1250-gs.js" /* webpackChunkName: "component---src-pages-bmw-bmw-r-1250-gs-js" */),
  "component---src-pages-bmw-bmw-r-1250-rt-js": () => import("./../../../src/pages/bmw/bmw-r1250-rt.js" /* webpackChunkName: "component---src-pages-bmw-bmw-r-1250-rt-js" */),
  "component---src-pages-bmw-bmw-r-1300-gs-js": () => import("./../../../src/pages/bmw/bmw-r1300-gs.js" /* webpackChunkName: "component---src-pages-bmw-bmw-r-1300-gs-js" */),
  "component---src-pages-book-index-js": () => import("./../../../src/pages/book/index.js" /* webpackChunkName: "component---src-pages-book-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-fully-guided-tour-booking-form-js": () => import("./../../../src/pages/fully-guided-tour-booking-form.js" /* webpackChunkName: "component---src-pages-fully-guided-tour-booking-form-js" */),
  "component---src-pages-guided-tours-index-js": () => import("./../../../src/pages/guided-tours/index.js" /* webpackChunkName: "component---src-pages-guided-tours-index-js" */),
  "component---src-pages-guided-tours-island-hopper-trails-js": () => import("./../../../src/pages/guided-tours/Island-Hopper-Trails.js" /* webpackChunkName: "component---src-pages-guided-tours-island-hopper-trails-js" */),
  "component---src-pages-guided-tours-south-island-hopper-js": () => import("./../../../src/pages/guided-tours/South-Island-Hopper.js" /* webpackChunkName: "component---src-pages-guided-tours-south-island-hopper-js" */),
  "component---src-pages-guided-tours-tours-additional-information-js": () => import("./../../../src/pages/guided-tours/tours-additional-information.js" /* webpackChunkName: "component---src-pages-guided-tours-tours-additional-information-js" */),
  "component---src-pages-honda-honda-cb-500-xa-js": () => import("./../../../src/pages/honda/honda-CB500XA.js" /* webpackChunkName: "component---src-pages-honda-honda-cb-500-xa-js" */),
  "component---src-pages-honda-honda-cmx-500-js": () => import("./../../../src/pages/honda/honda-cmx500.js" /* webpackChunkName: "component---src-pages-honda-honda-cmx-500-js" */),
  "component---src-pages-honda-honda-vt-750-s-js": () => import("./../../../src/pages/honda/honda-vt750s.js" /* webpackChunkName: "component---src-pages-honda-honda-vt-750-s-js" */),
  "component---src-pages-honda-honda-xl-750-trasalp-js": () => import("./../../../src/pages/honda/honda-xl750trasalp.js" /* webpackChunkName: "component---src-pages-honda-honda-xl-750-trasalp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rental-motorcycles-js": () => import("./../../../src/pages/rental-motorcycles.js" /* webpackChunkName: "component---src-pages-rental-motorcycles-js" */),
  "component---src-pages-self-guided-tours-2-week-deluxe-self-guided-js": () => import("./../../../src/pages/self-guided-tours/2-week-deluxe-self-guided.js" /* webpackChunkName: "component---src-pages-self-guided-tours-2-week-deluxe-self-guided-js" */),
  "component---src-pages-self-guided-tours-3-week-deluxe-self-guided-js": () => import("./../../../src/pages/self-guided-tours/3-week-deluxe-self-guided.js" /* webpackChunkName: "component---src-pages-self-guided-tours-3-week-deluxe-self-guided-js" */),
  "component---src-pages-self-guided-tours-index-js": () => import("./../../../src/pages/self-guided-tours/index.js" /* webpackChunkName: "component---src-pages-self-guided-tours-index-js" */),
  "component---src-pages-self-guided-tours-tours-additional-information-js": () => import("./../../../src/pages/self-guided-tours/tours-additional-information.js" /* webpackChunkName: "component---src-pages-self-guided-tours-tours-additional-information-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-suzuki-suzuki-dl-650-js": () => import("./../../../src/pages/suzuki/suzuki-dl650.js" /* webpackChunkName: "component---src-pages-suzuki-suzuki-dl-650-js" */),
  "component---src-pages-triumph-triumph-tiger-800-js": () => import("./../../../src/pages/triumph/triumph-tiger-800.js" /* webpackChunkName: "component---src-pages-triumph-triumph-tiger-800-js" */),
  "component---src-pages-yamaha-yamaha-tenere-700-js": () => import("./../../../src/pages/yamaha/yamaha-tenere-700.js" /* webpackChunkName: "component---src-pages-yamaha-yamaha-tenere-700-js" */),
  "component---src-pages-yamaha-yamaha-tracer-900-gt-js": () => import("./../../../src/pages/yamaha/yamaha-tracer-900-GT.js" /* webpackChunkName: "component---src-pages-yamaha-yamaha-tracer-900-gt-js" */)
}

